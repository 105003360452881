import type { MouseEventHandler, ReactNode } from "react";
import Button from "./Button";
import clsx from "clsx";

const ActionButton = ({
  title,
  href,
  target,
  icon,
  onClick,
  disabled,
}: {
  title: string;
  href?: string | undefined;
  target?: string | undefined;
  icon?: ReactNode;
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  disabled?: boolean;
}) => {
  return (
    <Button
      href={href}
      target={target}
      disabled={disabled}
      onClick={onClick}
      className={clsx(
        "inline-block rounded-md bg-blue-lightest px-1 pb-1 pt-2 text-center text-blue xs:px-3 md:pb-2 md:pt-3",

        {
          "hover:bg-blue-lighter hover:text-white": !disabled,
          "active:bg-blue active:text-white": !disabled,
          "cursor-default bg-gray-light text-gray": disabled,
        },
      )}
    >
      <span
        className={clsx({
          "flex flex-col items-center justify-center gap-2 @lg:flex-row @lg:pb-2 @lg:pt-1":
            icon,
        })}
      >
        {icon ? <span className="flex justify-center ">{icon}</span> : false}
        <span className="inline-block w-full overflow-hidden text-ellipsis whitespace-nowrap text-[9px] font-bold uppercase 2xs:text-2xs sm:text-xs">
          {title}
        </span>
      </span>
    </Button>
  );
};

export default ActionButton;
