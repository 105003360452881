import { type NextPage } from "next";

import Layout from "~/components/layout/DefaultLayout";
import { ActionButton } from "~/components/ui";

/**
 * 404 Error page
 */
const NotFoundPage: NextPage<{}> = ({}) => {
  return (
    <Layout pageTitle="FAQ">
      <div className="text-center">
        <p className="text-gray-light">404</p>
        <h1 className="mb-10 text-2xl font-medium">Page Not Found</h1>
        <div className="prose">
          <p>Sorry, we were unable to locate the page you are looking for.</p>
          <br />
          <ActionButton href="/" title="Go Home" />
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
