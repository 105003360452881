import { MouseEventHandler, ReactNode } from "react";
import Link from "next/link";

import clsx from "clsx";

const Button = ({
  children,
  href,
  target,
  className,
  onClick,
  ariaLabel,
  disabled,
}: {
  children: ReactNode;
  href?: string | undefined;
  target?: string | undefined;
  className?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  ariaLabel?: string;
  disabled?: boolean;
}) => {
  return href ? (
    <Link
      href={disabled ? "" : href}
      target={target}
      onClick={onClick}
      className={clsx(className)}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : undefined}
    >
      {children}
    </Link>
  ) : (
    <button
      className={clsx(className)}
      onClick={onClick}
      aria-label={ariaLabel}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
